import React from 'react';
import img from '../images/Web-developer.svg';
import { Link } from 'react-router-dom';

const Intro = () => {
    return (
        <div className="m-auto max-w-6xl p-4 md:p-12 h-auto" id='about'>
            <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                
                {/* Image Section */}
                <div className="lg:w-1/2 flex flex-col justify-center lg:mx-4">
                    <img 
                        alt="Web developer" 
                        className="rounded-lg shadow-lg w-full object-cover" 
                        src={img} 
                    />
                </div>
                
                {/* Text Section */}
                <div className="lg:w-1/2 flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end px-8">
                    <h3 className="text-4xl text-blue-900 font-bold mb-4">Our Scope</h3>
                    
                    <p className='my-3 text-xl text-gray-600 font-semibold leading-relaxed'>
                        At Catech, we specialize in designing, supplying, and installing light current & security system solutions. This includes:
                    </p>
                    
                    <ul className="list-disc list-inside mb-4 text-lg text-gray-700 font-medium">
                        <li>CCTV Systems</li>
                        <li>Access Control</li>
                        <li>Intrusion Detection</li>
                        <li>Gates & Turnstiles</li>
                        <li>Anti-shoplifting EAS & RFID Solutions</li>
                    </ul>

                    <p className='my-3 text-xl text-gray-600 font-semibold leading-relaxed'>
                        We cater to all types of retail, commercial, industrial, residential, and governmental applications, providing turnkey projects tailored to your specific needs and budget.
                    </p>
                    
                    <Link 
                        to="/contact" 
                        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group transition-transform duration-300"
                    >
                        Contact Us
                        <svg className="w-4 h-4 ml-1 group-hover:translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Intro;