import React from 'react';
import ad from '../images/clients/AD.png';
import sw from '../images/clients/SW.png';
import sen from '../images/clients/sensormatic.svg';

const clientImageStyle = {
    width: 'auto',
    mixBlendMode: 'colorBurn',
    display: 'flex',
    flexDirection: 'column', // Stack image and text vertically
    justifyContent: 'center',
    alignItems: 'center', // Center them horizontally
    padding: '1rem',  // Add some padding around the images
};

const Clients = () => {
    return (
        <div className="mt-16 bg-gray-100">
            {/* Team Section */}
            <section className="py-8 text-center" data-aos="fade-up">
                <h2 className="text-3xl text-blue-900 font-bold mb-4">Meet Our Team</h2>
                <div className="mt-2 mb-4">
                    <div className="w-24 border-b-4 border-blue-900 mx-auto"></div>
                </div>
                <p className="text-lg lg:text-xl text-gray-700 mx-4">
                    We have a team of professional, enthusiastic, and well-trained individuals dedicated to serving our valued customers with excellent skills and experiences. Our goal is to meet their satisfaction in implementation, after-sales, and maintenance service.
                </p>
            </section>

            {/* Partners Section */}
            <section className="py-8" data-aos="fade-up">
                <div className="my-4 text-center">
                    <h2 className="text-3xl text-blue-900 uppercase font-bold">Our Partners</h2>
                    <div className="mt-2 mb-4">
                        <div className="w-24 border-b-4 border-blue-900 mx-auto"></div>
                    </div>
                    <h2 className="text-xl lg:text-2xl font-semibold text-blue-900">
                        Some of our Partners
                    </h2>
                </div>

                {/* Client Logos Section */}
                <div className="p-5" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 justify-items-center">
                        <div style={clientImageStyle} className="transition-opacity duration-300 ease-in-out opacity-80 hover:opacity-100">
                            <img src={ad} alt="American Dynamics Supplier" className="h-24 object-contain" />
                            <p className="mt-2 text-gray-600 text-center">American Dynamics</p>
                        </div>
                        <div style={clientImageStyle} className="transition-opacity duration-300 ease-in-out opacity-80 hover:opacity-100">
                            <img src={sen} alt="Sensormatic Supplier" className="h-24 object-contain" />
                            <p className="mt-2 text-gray-600 text-center">Sensormatic</p>
                        </div>
                        <div style={clientImageStyle} className="transition-opacity duration-300 ease-in-out opacity-80 hover:opacity-100">
                            <img src={sw} alt="SW House Supplier" className="h-24 object-contain" />
                            <p className="mt-2 text-gray-600 text-center">SW House</p>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
        );
    };

    export default Clients;