import React from "react";
import { HashLink } from "react-router-hash-link";

const NavLinks = () => {
  const navLinks = [
    { to: "/#intro", label: "Home" },
    { to: "/#about", label: "About", smooth: true },
    { to: "/contact#contact", label: "Contact Us" },
  ];
  return (
    <nav className="flex justify-center space-x-4 py-2">
        {navLinks.map(({ to, label, smooth }) => (
            <HashLink
                key={label} // Ensure this is unique in your context
                className="px-4 py-2 font-extrabold text-gray-500 hover:text-blue-900 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                to={to}
                smooth={smooth}
                activeClassName="text-blue-900" // Tailwind class for active state
            >
                {label}
            </HashLink>
        ))}
    </nav>
  );
};

export default NavLinks;
